import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Editor as SlateEditor, Transforms, createEditor } from 'slate';
import { Slate, Editable, withReact, useSlate } from 'slate-react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import isHotkey from 'is-hotkey';

import { useChangingDebounce } from 'hooks/useChangingDebounce';
import { useChangingCallbacks } from 'hooks/useChangingCallbacks';
import ScrollWrapper from 'components/ScrollWrapper';

import { countStatistics } from './statistics';
import StatisticsPanel from './StatisticsPanel';

const MIN_FONT_SIZE = 6;
const MAX_FONT_SIZE = 48;
const DEFAULT_FONT_SIZE = 16;
const LIST_TYPES = ['numbered-list', 'bulleted-list']

function Editor({body, typingStopTimeout, onIncreasePlaybackRate, onDecreasePlaybackRate, onPlay, onSave, onSkipBackward, onSkipForward, onTypingStart, onTypingStop}) {
  const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE);
  const [statistics, setStatistics] = useState(countStatistics(body));
  const editor = useMemo(() => withReact(createEditor()), []);
  const [value, setValue] = useState(body);

  // Hotkeys
  const CALLBACK_HOTKEYS = useMemo(() => { 
    return {
      'alt+,': () => { onSkipBackward && onSkipBackward() },
      'alt+.': () => { onSkipForward && onSkipForward() },
      'alt+[': () => { onDecreasePlaybackRate && onDecreasePlaybackRate() },
      'alt+]': () => { onIncreasePlaybackRate && onIncreasePlaybackRate() },
      'alt+space': () => { onPlay && onPlay() },
    }
  }, [onSkipBackward, onSkipForward, onDecreasePlaybackRate, onIncreasePlaybackRate, onPlay]);

  const EDITOR_HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
  };

  const onEditableKeyDown = (event) => {
    for(const hotkey in CALLBACK_HOTKEYS) {
      if(isHotkey(hotkey, event)) {
        event.preventDefault();
        CALLBACK_HOTKEYS[hotkey]();
        return;
      }
    }
    for(const hotkey in EDITOR_HOTKEYS) {
      if(isHotkey(hotkey, event)) {
        event.preventDefault();
        toggleMark(editor, EDITOR_HOTKEYS[hotkey]);
        return;
      }
    }
  }
  
  // Typing timers & timeouts
  const [isTyping, debouncedValue] = useChangingDebounce(value, typingStopTimeout);
  useChangingCallbacks(isTyping, onTypingStart, onTypingStop);

  useEffect(() => {
    setStatistics(countStatistics(debouncedValue));
    onSave && onSave(debouncedValue);
  }, [debouncedValue, onSave]);
  

  // Rendering
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  return (
    <>
      <ButtonGroup style={ZoomToolbarStyle}>
        <Button 
          disabled={fontSize <= MIN_FONT_SIZE}
          icon="small-minus"
          onClick={() => setFontSize(fontSize > MIN_FONT_SIZE ? fontSize - 1 : MIN_FONT_SIZE)} />
        <Button 
          disabled={fontSize >= MAX_FONT_SIZE}
          icon="small-plus"
          onClick={() => setFontSize(fontSize < MAX_FONT_SIZE ? fontSize + 1 : MAX_FONT_SIZE)} />
      </ButtonGroup>

      <ScrollWrapper style={WrapperStyle}>
        <Slate 
          editor={editor} 
          value={value} 
          onChange={value => setValue(value)}>
          <div style={FormattingToolbarStyle}>

            <ButtonGroup>
              <MarkButton 
                icon="bold" 
                format="bold" />
              <MarkButton 
                icon="italic" 
                format="italic" />
              <MarkButton 
                icon="underline" 
                format="underline" />
            </ButtonGroup>

            <ButtonGroup>
              <BlockButton 
                format="heading-one" 
                icon="header-one" />
              <BlockButton 
                format="heading-two" 
                icon="header-two" />
              <BlockButton 
                format="block-quote" 
                icon="citation" />
              <BlockButton 
                format="numbered-list" 
                icon="numbered-list" />
              <BlockButton 
                format="bulleted-list" 
                icon="properties" />
            </ButtonGroup>
          </div>

            <Editable 
              autoFocus
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              style={{...EditableStyle, fontSize: `${fontSize}pt`}} 
              onKeyDown={onEditableKeyDown} />
        </Slate>
      </ScrollWrapper>

      <StatisticsPanel height={STATISTICS_HEIGHT} {...statistics} />
    </>
  );
}

const isBlockActive = (editor, format) => {
  const [match] = SlateEditor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = SlateEditor.marks(editor)
  return marks ? marks[format] === true : false
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    SlateEditor.removeMark(editor, format)
  } else {
    SlateEditor.addMark(editor, format, true)
  }
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      icon={icon} />
  );
}


const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
      icon={icon} />
  )
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  return <span {...attributes}>{children}</span>
}

const EDITABLE_PADDING = '36px';
const STATISTICS_HEIGHT = '24px';

const WrapperStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: STATISTICS_HEIGHT,
};

const EditableStyle = {
  position: 'absolute',
  left: EDITABLE_PADDING,
  top: EDITABLE_PADDING,
  right: EDITABLE_PADDING,
  bottom: EDITABLE_PADDING,
  lineHeight: '150%',
  border: 'none',
};

const FormattingToolbarStyle = {
  position: 'absolute',
  top: '12px',
  left: '12px',
};

const ZoomToolbarStyle = {
  position: 'absolute',
  top: '12px',
  right: '12px',
};

export default Editor;
