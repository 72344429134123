import React, { useCallback, useEffect, useState } from 'react';

import { Spinner } from '@blueprintjs/core';

const TIMEOUT = 1000;

function LoadingView({audioUrl, onLoad}) {
  const [error, setError] = useState(false);
  const [duration, setDuration] = useState(null);

  const onAudioLoadedMetadata = useCallback((event) => {
    const duration = event.target.duration;
    console.debug('LoadingView::onAudioMetadataLoaded', duration);
    setDuration(duration);
  }, []);

  const onLoadTimeout = useCallback(() => {
    onLoad && onLoad(duration)
  }, [onLoad, duration]);

  useEffect(() => {
    if(duration) {
      const loadedTimeoutId = setTimeout(onLoadTimeout, TIMEOUT);
      return () => clearTimeout(loadedTimeoutId);
    }
  }, [duration, onLoadTimeout]);

  if(error) {
    return (
      <div style={OuterWrapperStyle}>
        <div style={InnerWrapperStyle}>
          Wystąpił bład podczas ładowania pliku.
        </div>
      </div>
    );

  } else {
    return (
      <div style={OuterWrapperStyle}>
        <div style={InnerWrapperStyle}>
          <audio
            src={audioUrl}
            onLoadedMetadata={onAudioLoadedMetadata}
            onError={() => setError(true)}
          />

          <Spinner />
        </div>
      </div>
    )
  }
}

const OuterWrapperStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: '#f5f8fa',
}

const InnerWrapperStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50px',
  height: '50px',
  marginTop: '-25px',
  marginLeft: '-25px',
};

export default LoadingView;