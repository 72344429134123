import React from 'react';
import { FileInput } from '@blueprintjs/core';

import Modal from 'components/Modal';
import { useDocument } from 'hooks/useDocument';
import { secondsToHuman } from 'helpers/duration';

function AudioSelectView({documentId, onCancel, onFileSelect}) {
  const {
    title,
    audioFileName,
    audioDuration,
  } = useDocument(documentId);

  const onFileOpen = (event) => {
    const audioFile = event.target.files[0];
    onFileSelect && onFileSelect(audioFile);
  }

  return (
    <Modal title="Wybierz pasujący plik audio" onCancel={() => onCancel && onCancel()}>
      <p>Aby otworzyć zapisany dokument <q><b>{title}</b></q> wskaż, gdzie na dysku znajduje się pasujący do niego plik audio.</p>      
      <p>Plik audio użyty ostatnim razem nazywał się <q><b>{audioFileName}</b></q> i trwał <b>{secondsToHuman(audioDuration)}</b>.</p>      

      <FileInput 
        inputProps={{accept: "audio/*"}}        
        text="Wybierz plik z nagraniem&hellip;" 
        buttonText="Przeglądaj"
        fill={true}
        onInputChange={onFileOpen} 
      />
    </Modal>
  );
}

export default AudioSelectView;