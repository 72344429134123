import { v4 as uuidv4 } from 'uuid';

import CONFIG from 'config';

const FORMAT = 1; // For the future, use format versioning
const UUIDV4_REGEX = '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-4[a-fA-F0-9]{3}-[89abAB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}';
const DOCUMENT_META_REGEX = new RegExp(`^${CONFIG.STORAGE_PREFIX}-(${FORMAT})-(${UUIDV4_REGEX})-meta$`);
const GENERATE_DOCUMENT_ID_ATTEMPTS = 100;
const BODY_DEFAULT = [{type: 'paragraph', children: [{ text: '' }]}];
const POSITION_DEFAULT = 0;
const PLAYBACK_RATE_DEFAULT = 100;
const PLAYBACK_DURATION_DEFAULT = 10;
const VOLUME_DEFAULT = 100;
const SKIP_FORWARD_DEFAULT = 12;
const SKIP_BACKWARD_DEFAULT = 6;

// Private API

const generateDocumentId = () => {
  const localStorage = window.localStorage;
  let attemptsLeft = GENERATE_DOCUMENT_ID_ATTEMPTS;
  while(attemptsLeft > 0) {
    const documentId = uuidv4();
    if(!localStorage.getItem(documentId)) {
      return documentId;
    }
  }
  throw new Error('Unable to generate document ID, maximum attempts reached');
}

const readMetadata = (key) => {
  const localStorage = window.localStorage;

  const data = JSON.parse(localStorage.getItem(key));
  data.lastModified = new Date(data.lastModified);
  return data;
}


// Public, but low-level API, avoid using in the app

const saveDocument = (
  id, 
  body, 
  audioDuration,
  audioFileName,
  audioFileSize,
  lastModified,
  position,
  skipBackwardDuration,
  skipForwardDuration,
  playbackDuration,
  playbackRate,
  title,
  volume
) => {
  const localStorage = window.localStorage;
  const metadata = {
    audioDuration,
    audioFileName,
    audioFileSize,
    lastModified: lastModified.toISOString(),
    position,
    skipBackwardDuration,
    skipForwardDuration,
    playbackDuration,
    playbackRate,
    title,
    volume,
  };

  // Store metadata and body separately to avoid parsing body of all saved documents
  // while iterating in the WelcomeView.
  localStorage.setItem(`${CONFIG.STORAGE_PREFIX}-${FORMAT}-${id}-meta`, JSON.stringify(metadata));
  localStorage.setItem(`${CONFIG.STORAGE_PREFIX}-${FORMAT}-${id}-body`, JSON.stringify(body));

  return {
    id,
    format: FORMAT,
    metadata,
    body,
  };
};


// Public API

const createDocumentFromFile = (file, audioDuration) => {
  const id = generateDocumentId();
  const audioFileName = file.name;
  const audioFileSize = file.size; 
  
  return saveDocument(
    id,
    BODY_DEFAULT, 
    audioDuration,
    audioFileName,
    audioFileSize,
    new Date(),
    POSITION_DEFAULT,
    SKIP_BACKWARD_DEFAULT,
    SKIP_FORWARD_DEFAULT,
    PLAYBACK_DURATION_DEFAULT,
    PLAYBACK_RATE_DEFAULT,
    audioFileName,
    VOLUME_DEFAULT
  );
}

const listDocumentsMetadata = () => {
  const localStorage = window.localStorage;
  let documents = [];
  for (var i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    const match = key.match(DOCUMENT_META_REGEX);
    if(match) {
      documents.push({
        id: match[2],
        format: parseInt(match[1]),
        metadata: readMetadata(key),
      });
    }
  }
  
  return documents;
}

const loadDocument = (documentId) => {
  const localStorage = window.localStorage;
  const metadata = localStorage.getItem(`${CONFIG.STORAGE_PREFIX}-${FORMAT}-${documentId}-meta`);
  const body = localStorage.getItem(`${CONFIG.STORAGE_PREFIX}-${FORMAT}-${documentId}-body`);

  if(metadata && body) {
    return {
      id: documentId,
      format: FORMAT,
      metadata: JSON.parse(metadata),
      body: JSON.parse(body),
    }
  } else {
    return null;
  }
}

const updateDocumentBody = (id, updatedBody) => {
  const localStorage = window.localStorage;
  const metadataKey = `${CONFIG.STORAGE_PREFIX}-${FORMAT}-${id}-meta`;
  const metadata = localStorage.getItem(metadataKey);

  if(metadata) {
    const bodyKey = `${CONFIG.STORAGE_PREFIX}-${FORMAT}-${id}-body`;

    const currentMetadata = JSON.parse(metadata);
    const newMetadata = {...currentMetadata, lastModified: new Date().toISOString()};
    localStorage.setItem(metadataKey, JSON.stringify(newMetadata));
    localStorage.setItem(bodyKey, JSON.stringify(updatedBody));
    return true;

  } else {
    return false;
  }
}

const updateDocumentMetadata = (id, updatedMetadata) => {
  const localStorage = window.localStorage;
  const key = `${CONFIG.STORAGE_PREFIX}-${FORMAT}-${id}-meta`;
  const metadata = localStorage.getItem(key);

  if(metadata) {
    const currentMetadata = JSON.parse(metadata);
    const newMetadata = Object.assign({}, currentMetadata, updatedMetadata);
    localStorage.setItem(key, JSON.stringify(newMetadata));
    return true;
  } else {
    return false;
  }
}

export { 
  createDocumentFromFile,
  saveDocument,
  loadDocument,
  listDocumentsMetadata,
  updateDocumentBody,
  updateDocumentMetadata 
};