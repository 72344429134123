import React, { useEffect, useRef, useState } from 'react';

import { usePrevious } from 'hooks/usePrevious';

function ScrollWrapper({children, style}) {
  const outerWrapper = useRef(null);
  const innerWrapper = useRef(null);
  const [innerHeight, setInnerHeight] = useState(null);
  const [scrollTop, setScrollTop] = useState(null);
  const [scrollTopMax, setScrollTopMax] = useState(null);
  const prevScrollTopMax = usePrevious(scrollTopMax);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setInnerHeight(innerWrapper.current.offsetHeight);
    setScrollTop(outerWrapper.current.scrollTop);
    setScrollTopMax(outerWrapper.current.scrollTopMax);
  });

  useEffect(() => {
    if(scrollTop === prevScrollTopMax) {
      outerWrapper.current.scrollTo({
        top: scrollTopMax,
        left: 0,
      });
    }
  }, [innerHeight, scrollTop, scrollTopMax, prevScrollTopMax]);

  return (
    <div ref={outerWrapper} style={{...style, ...WrapperStyle}}>
      <div ref={innerWrapper}>
        {children}
      </div>
    </div>
  );
}

const WrapperStyle = {
  overflowX: 'scroll',
};

export default ScrollWrapper;