import { useEffect } from 'react';
import { usePrevious } from 'hooks/usePrevious';

// This hook monitors boolean value passed as isChanging and 
// 
// * calls onStart() if it changes value from false to true 
// * calls onStop() if it changes value from true to false
function useChangingCallbacks(isChanging, onStart, onStop) {
  const prevIsChanging = usePrevious(isChanging);

  useEffect(() => {
    if(prevIsChanging !== undefined && prevIsChanging !== isChanging) {
      if(isChanging) {
        onStart && onStart();
      } else {
        onStop && onStop();
      }
    }
  }, [isChanging, prevIsChanging, onStart, onStop]);
}

export { useChangingCallbacks };