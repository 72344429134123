import React from 'react';

function SavedDocument({document, onClick}) {

  return (
    <li>
      <button onClick={() => onClick(document)}>{document.metadata.title}</button>
    </li>
  );
}

export default SavedDocument;