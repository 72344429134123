import React from 'react';
import { FileInput } from '@blueprintjs/core';

import Modal from 'components/Modal';
import { listDocumentsMetadata } from 'data/storage';

import SavedDocument from './SavedDocument';

function WelcomeView({onNewFileOpen, onSavedDocumentOpen}) {
  const onFileOpen = (event) => {
    const audioFile = event.target.files[0];
    onNewFileOpen && onNewFileOpen(audioFile);
  }

  const onDocumentOpen = (document) => {
    onSavedDocumentOpen && onSavedDocumentOpen(document);
  }

  const documentsMetadata = listDocumentsMetadata();

  return (
    <Modal>
      {documentsMetadata.length !== 0 &&
        <>
          <h1>Dokumenty zapisane na tym komputerze</h1>
          <ul>
            {documentsMetadata
              .sort((documentA, documentB) => documentB.metadata.lastModified - documentA.metadata.lastModified)
              .map((document) => <SavedDocument key={document.id} document={document} onClick={onDocumentOpen} />)}
          </ul>
        </>
      }

      <h1>Otwórz nowy plik audio</h1>
      <FileInput 
        inputProps={{accept: "audio/*"}}        
        text="Wybierz plik z nagraniem&hellip;" 
        buttonText="Przeglądaj"
        fill={true}
        onInputChange={onFileOpen} 
      />
    </Modal>
  );
}

export default WelcomeView;