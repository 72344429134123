import { useCallback, useState } from 'react';

import { loadDocument, updateDocumentBody, updateDocumentMetadata } from 'data/storage';

function useDocument(id) {
  const [document, setDocument] = useState(loadDocument(id));
  console.log(document);
  
  // Body
  const setBody = useCallback((body) => {
    if(updateDocumentBody(document.id, body)) {
      setDocument(loadDocument(document.id));
    }
  }, [document.id]);
  
  // Metadata
  const setAudioDuration = useCallback((audioDuration) => {
    if(updateDocumentMetadata(document.id, {audioDuration})) {
      setDocument(loadDocument(document.id));
    }
  }, [document.id]);  

  const setAudioFileName = useCallback((audioFileName) => {
    if(updateDocumentMetadata(document.id, {audioFileName})) {
      setDocument(loadDocument(document.id));
    }
  }, [document.id]);  

  const setAudioFileSize = useCallback((audioFileSize) => {
    if(updateDocumentMetadata(document.id, {audioFileSize})) {
      setDocument(loadDocument(document.id));
    }
  }, [document.id]);  
  
  const setTitle = useCallback((title) => {
    if(updateDocumentMetadata(document.id, {title})) {
      setDocument(loadDocument(document.id));
    }
  }, [document.id]);  

  const setPosition = useCallback((position) => {
    if(updateDocumentMetadata(document.id, {position})) {
      setDocument(loadDocument(document.id));
    }
  }, [document.id]);

  const setVolume = useCallback((volume) => {
    if(updateDocumentMetadata(document.id, {volume})) {
      setDocument(loadDocument(document.id));
    }
  }, [document.id]);

  const setPlaybackRate = useCallback((playbackRate) => {
    if(updateDocumentMetadata(document.id, {playbackRate})) {
      setDocument(loadDocument(document.id));
    }
  }, [document.id]);

  const setPlaybackDuration = useCallback((playbackDuration) => {
    if(updateDocumentMetadata(document.id, {playbackDuration})) {
      setDocument(loadDocument(document.id));
    }
  }, [document.id]);

  const setSkipForwardDuration = useCallback((skipForwardDuration) => {
    if(updateDocumentMetadata(document.id, {skipForwardDuration})) {
      setDocument(loadDocument(document.id));
    }
  }, [document.id]);

  const setSkipBackwardDuration = useCallback((skipBackwardDuration) => {
    if(updateDocumentMetadata(document.id, {skipBackwardDuration})) {
      setDocument(loadDocument(document.id));
    }
  }, [document.id]);

  return {
    audioDuration: document.metadata.audioDuration,
    setAudioDuration,
    audioFileName: document.metadata.audioFileName,
    setAudioFileName,
    audioFileSize: document.metadata.audioFileSize,
    setAudioFileSize,
    body: document.body, 
    setBody,
    position: document.metadata.position,
    setPosition,
    skipBackwardDuration: document.metadata.skipBackwardDuration,
    setSkipBackwardDuration,
    skipForwardDuration: document.metadata.skipForwardDuration,
    setSkipForwardDuration,
    playbackDuration: document.metadata.playbackDuration,
    setPlaybackDuration,
    playbackRate: document.metadata.playbackRate,
    setPlaybackRate,
    title: document.metadata.title,
    setTitle, 
    volume: document.metadata.volume,
    setVolume,
  };
}

export { useDocument };