import React, { useState } from 'react';
import './App.css';

import EditorView from 'views/EditorView';
import AudioSelectView from 'views/AudioSelectView';
import LoadingView from 'views/LoadingView';
import WelcomeView from 'views/WelcomeView';

import { createDocumentFromFile } from 'data/storage';
import { useAudioUrl } from 'hooks/useAudioUrl';

function App() {
  const [document, setDocument] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const audioUrl = useAudioUrl(audioFile);

  const onNewFileOpen = (audioFile) => {
    console.debug('App::onNewFileOpen', audioFile);
    setAudioFile(audioFile);
  }

  const onNewFileLoad = (duration) => {
    console.debug('App::onNewFileLoad', duration);
    const document = createDocumentFromFile(audioFile, duration);
    setDocument(document);
  }

  const onDocumentClose = () => {
    console.debug('App::onDocumentClose');
    setDocument(null);
    setAudioFile(null);
  }

  const onSavedDocumentOpen = (document) => {
    console.debug('App::onSavedDocumentOpen', document);
    setDocument(document);
  }

  if(!document && !audioFile) {
    return (
      <WelcomeView 
        onNewFileOpen={onNewFileOpen}
        onSavedDocumentOpen={onSavedDocumentOpen}
      />
    );

  } else if(!document && audioFile) {
    return (
      <LoadingView
        audioUrl={audioUrl}
        onLoad={onNewFileLoad}
      />
    );

  } else if(document && !audioFile) {
    return (
      <AudioSelectView
        documentId={document.id}
        onCancel={() => setDocument(null)}
        onFileSelect={(audioFile) => setAudioFile(audioFile)}
      />
    );
  
  } else {
    return (
      <EditorView 
        audioUrl={audioUrl}
        documentId={document.id}
        onDocumentClose={onDocumentClose}
      />
    );
  }
}

export default App;
