import { useEffect, useState } from 'react';

function useAudioUrl(audioFile) {
  const [audioUrl, setAudioUrl] = useState(null);

  useEffect(() => {
    if(audioFile) {
      const audioUrl = URL.createObjectURL(audioFile);
      console.debug('useAudioUrl Created URL for audio file', audioUrl, audioFile);
      setAudioUrl(audioUrl);

      return () => {
        console.debug('useAudioUrl Revoked URL for audio file', audioUrl);
        URL.revokeObjectURL(audioUrl);
      }
    }
  }, [audioFile]);

  return audioUrl;
}

export { useAudioUrl };