import { useState, useEffect, useRef } from 'react';
import { useDebounce } from 'hooks/useDebounce';

// This hook works similarily to useDebounce but additionally returns a boolean
// value indicating whether the value is currently being changed (is during debounce
// period).
function useChangingDebounce(value, delay) {
  const debouncedValue = useDebounce(value, delay);
  const [isChanging, setIsChanging] = useState(false);
  
  const first = useRef(true);
  useEffect(() => {
    if(first.current) {
      first.current = false;
      return;
    }

    setIsChanging(true);
  }, [value]);

  useEffect(() => {
    setIsChanging(false);
  }, [debouncedValue]);

  return [isChanging, debouncedValue];
};

export { useChangingDebounce };