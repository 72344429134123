import React, { useCallback, useEffect, useRef } from 'react';
import { usePrevious } from 'hooks/usePrevious';

function Audio({src, position, playbackRate, playing, onAudioPositionChange, volume}) {
  const prevPlaying = usePrevious(playing);
  const audioElement = useRef(null);

  const onTimeUpdate = useCallback((event) => {
    onAudioPositionChange && onAudioPositionChange(event.target.currentTime);
  }, [onAudioPositionChange]);

  useEffect(() => {
    if(prevPlaying === true && playing === false) {
      audioElement.current.pause();
    } else if(prevPlaying === false && playing === true) {
      audioElement.current.play();
    }
  }, [playing, prevPlaying]);

  useEffect(() => {
    if(position !== audioElement.current.currentTime) {
      audioElement.current.currentTime = position;
    }
  }, [position]);

  useEffect(() => {
    if(playbackRate !== audioElement.current.playbackRate) {
      audioElement.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  useEffect(() => {
    if(volume !== audioElement.current.volume) {
      audioElement.current.volume = volume;
    }
  }, [volume]);

  return (
    <audio 
      src={src}
      ref={audioElement}        
      preload="auto"
      onTimeUpdate={onTimeUpdate} />
  );
}

export default Audio;