import React from 'react';

import { Button } from '@blueprintjs/core';

function Modal({children, title, onCancel}) {
  return (
    <div style={OuterWrapperStyle}>
      <div style={InnerWrapperStyle}>
        <header>
          {onCancel && 
            <Button 
              icon="cross" 
              minimal={true}
              onClick={() => onCancel && onCancel()} 
              style={CancelButtonStyle} />
          }
          <h1>{title}</h1>
        </header>

        <main style={MainStyle}>
          {children}
        </main>
      </div>
    </div>
  );
}

const OuterWrapperStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: '#f5f8fa',
}

const InnerWrapperStyle = {
  width: '100ex',
  margin: '0 auto',
}

const MainStyle = {
  border: '1px solid #e3e6e8',
  background: '#fff',
  padding: '24px',
  lineHeight: '150%',
}

const CancelButtonStyle = {
  float: 'right',
}

export default Modal;
