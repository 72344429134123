import React, { useCallback, useMemo } from 'react';
import { Button, ButtonGroup, Slider } from "@blueprintjs/core";

import { useWindowSize } from 'hooks/useWindowSize';
import { secondsToHuman } from 'helpers/duration';

function Player({duration, playbackDuration, playing, position, onSeek, onSkipBackward, onSkipForward, skipBackwardDuration, skipForwardDuration, onPlay}) {
  const onSkipBackwardClick = useCallback(() => {
    onSkipBackward && onSkipBackward(skipBackwardDuration);
  }, [onSkipBackward, skipBackwardDuration]);

  const onSkipForwardClick = useCallback(() => {
    onSkipForward && onSkipForward(skipForwardDuration);
  }, [onSkipForward, skipForwardDuration]);

  const onPositionChange = useCallback((value) => {
    onSeek && onSeek(value);
  }, [onSeek]);

  const onPlayClick = useCallback(() => {
    onPlay && onPlay();
  }, [onPlay]);

  const size = useWindowSize();
  const labelStepSize = useMemo(() => 
    size.width > 1440 ? 16 : (      
      size.width > 1200 ? 10 : (
        size.width > 992 ? 8 : (
          size.width > 768 ? 6 : (
            size.width > 576 ? 4 : 2
          )
        )
      )
    ), [size.width]);

  return (
    <>
      <div style={{...OuterWrapperStyle, right: '600px'}}>
        <div style={InnerWrapperStyle}>
          <Slider 
            initialValue={0}
            min={0}
            max={duration} 
            labelStepSize={Math.ceil(duration / labelStepSize)} 
            showTrackFill={false}
            value={position}
            labelRenderer={(value) => secondsToHuman(value)}
            onChange={onPositionChange} />
        </div>
      </div>

      <div style={{...OuterWrapperStyle, width: '600px', left: 'auto'}}>
        <div style={InnerWrapperStyle}>
          <ButtonGroup>
            <Button 
              icon="play"
              active={playing}
              text={`Odtwarzaj ${playbackDuration}s`}
              onClick={onPlayClick} />
          </ButtonGroup>
          <ButtonGroup>
            <Button 
              icon="step-backward"
              text={`Wstecz o ${skipBackwardDuration}s`} 
              onClick={onSkipBackwardClick} />
            <Button 
              rightIcon="step-forward"
              text={`W przód o ${skipForwardDuration}s`} 
              onClick={onSkipForwardClick} />
          </ButtonGroup>
        </div>
      </div>
    </>
  );
}

const OuterWrapperStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const InnerWrapperStyle = {
  position: 'absolute',
  top: '16px',
  bottom: '16px',
  left: '32px',
  right: '32px',
};

export default Player;