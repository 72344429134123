import React, { useCallback, useEffect, useState } from 'react';
import { Button, EditableText, Label, Slider } from "@blueprintjs/core";

import Audio from 'components/Audio';
import { useTitle } from 'hooks/useTitle';
import { useDocument } from 'hooks/useDocument';

import Editor from './Editor';
import Player from './Player';

const SKIP_DURATION_MIN = 1;
const SKIP_DURATION_MAX = 30;
const PLAYBACK_RATE_MIN = 25;
const PLAYBACK_RATE_MAX = 175;
const PLAYBACK_RATE_HOTKEY_STEP = 10;
const PLAYBACK_DURATION_MIN = 1;
const PLAYBACK_DURATION_MAX = 60;

function EditorView({audioUrl, documentId, onDocumentClose}) {
  const {
    audioDuration,
    body,
    setBody,
    position,
    setPosition,
    skipBackwardDuration,
    setSkipBackwardDuration,
    skipForwardDuration,
    setSkipForwardDuration,
    playbackDuration,
    setPlaybackDuration,
    playbackRate,
    setPlaybackRate,
    title,
    setTitle,
    volume, 
    setVolume,
  } = useDocument(documentId);

  const [playing, setPlaying] = useState(false);

  useTitle(title);

  const skipForward = useCallback(() => {
    setPosition(position + skipForwardDuration <= audioDuration ? position + skipForwardDuration : audioDuration);
  }, [position, setPosition, skipForwardDuration, audioDuration]);

  const skipBackward = useCallback(() => {
    setPosition(position - skipBackwardDuration >= 0 ? position - skipBackwardDuration : 0);
  }, [position, setPosition, skipBackwardDuration]);

  const onAudioPositionChange = useCallback((position) => {
    console.debug('EditorView::onAudioPositionChange', position);
    setPosition(position);
  }, [setPosition]);

  const onPlayerSeek = useCallback((position) => {
    console.debug('EditorView::onPlayerSeek', position);
    setPosition(position);
  }, [setPosition]);

  const onPlayerSkipBackward = useCallback(() => {
    console.debug('EditorView::onPlayerSkipBackward');
    skipBackward();
  }, [skipBackward]);

  const onPlayerSkipForward = useCallback(() => {
    console.debug('EditorView::onPlayerSkipForward');
    skipForward();
  }, [skipForward]);

  const onEditorSave = useCallback((newBody) => {
    console.debug('EditorView::onEditorSave');
    setBody(newBody);
  }, [setBody]);

  const onEditorSkipBackward = useCallback(() => {
    console.debug('EditorView::onEditorSkipBackward');
    skipBackward();
  }, [skipBackward]);

  const onEditorSkipForward = useCallback(() => {
    console.debug('EditorView::onEditorSkipForward');
    skipForward();
  }, [skipForward]);

  const onEditorTypingStart = useCallback(() => {
    console.debug('EditorView::onEditorTypingStart');
  }, []);

  const onEditorTypingStop = useCallback(() => {
    console.debug('EditorView::onEditorTypingStop');
    setPlaying(true);
  }, []);

  const onEditorDecreasePlaybackRate = useCallback(() => {
    console.debug('EditorView::onEditorDecreasePlaybackRate');
    setPlaybackRate(playbackRate - PLAYBACK_RATE_HOTKEY_STEP >= PLAYBACK_RATE_MIN ? playbackRate - PLAYBACK_RATE_HOTKEY_STEP : PLAYBACK_RATE_MIN);
  }, [playbackRate, setPlaybackRate]);

  const onEditorIncreasePlaybackRate = useCallback(() => {
    console.debug('EditorView::onEditorIncreasePlaybackRate');
    setPlaybackRate(playbackRate + PLAYBACK_RATE_HOTKEY_STEP <= PLAYBACK_RATE_MAX ? playbackRate + PLAYBACK_RATE_HOTKEY_STEP : PLAYBACK_RATE_MAX);
  }, [playbackRate, setPlaybackRate]);

  const onEditorPlay = useCallback(() => {
    console.debug('EditorView::onEditorPlay');
    setPlaying(!playing);
  }, [playing]);

  const onPlayerPlay = useCallback(() => {
    console.debug('EditorView::onPlayerPlay');
    setPlaying(!playing);
  }, [playing]);

  useEffect(() => {
    if(playing) {
      const timeoutId = setTimeout(() => {
        setPlaying(false);
      }, playbackDuration * 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [playing, playbackDuration]);

  return (
    <>
      <Audio
        src={audioUrl}
        playing={playing}
        position={position}
        playbackRate={playbackRate / 100}
        volume={volume / 100}
        onAudioPositionChange={onAudioPositionChange} />

      <header style={HeaderStyle}>
        <Button 
          style={CloseButtonStyle}
          icon="cross"
          onClick={() => onDocumentClose && onDocumentClose()} 
          minimal={true} 
        /> 
        <h1>
          <EditableText 
            value={title}
            onChange={(value) => setTitle(value)} 
          />
        </h1>
      </header>

      <main style={MainStyle}>
        <Editor 
          body={body}
          onDecreasePlaybackRate={onEditorDecreasePlaybackRate}
          onIncreasePlaybackRate={onEditorIncreasePlaybackRate} 
          typingStopTimeout={1000}
          onSave={onEditorSave}
          onPlay={onEditorPlay} 
          onSkipBackward={onEditorSkipBackward}
          onSkipForward={onEditorSkipForward} 
          onTypingStart={onEditorTypingStart}
          onTypingStop={onEditorTypingStop}
        />
      </main>

      <aside style={AsideStyle}>          
        <Label style={AsideLabelStyle}>
          Tempo odtwarzania
          <Slider 
            min={PLAYBACK_RATE_MIN}
            max={PLAYBACK_RATE_MAX} 
            labelStepSize={25} 
            showTrackFill={false}
            value={playbackRate}
            labelRenderer={(value) => `${value}%`}
            onChange={(value) => setPlaybackRate(value)} />
        </Label>

        <Label style={AsideLabelStyle}>
          Głośność
          <Slider 
            min={0}
            max={100} 
            labelStepSize={25} 
            showTrackFill={false}
            value={volume}
            labelRenderer={(value) => `${value}%`}
            onChange={(value) => setVolume(value)} />
        </Label>

        <Label style={AsideLabelStyle}>
          Przeskakiwanie w tył
          <Slider 
            min={SKIP_DURATION_MIN}
            max={SKIP_DURATION_MAX} 
            labelStepSize={5} 
            showTrackFill={false}
            value={skipBackwardDuration}
            labelRenderer={(value) => `${value}s`}
            onChange={(value) => setSkipBackwardDuration(value)} />
        </Label>

        <Label style={AsideLabelStyle}>
          Przeskakiwanie w przód
          <Slider 
            min={SKIP_DURATION_MIN}
            max={SKIP_DURATION_MAX} 
            labelStepSize={5} 
            showTrackFill={false}
            value={skipForwardDuration}
            labelRenderer={(value) => `${value}s`}
            onChange={(value) => setSkipForwardDuration(value)} />
        </Label>

        <Label style={AsideLabelStyle}>
          Czas odtwarzania
          <Slider 
            min={PLAYBACK_DURATION_MIN}
            max={PLAYBACK_DURATION_MAX} 
            labelStepSize={10} 
            showTrackFill={false}
            value={playbackDuration}
            labelRenderer={(value) => `${value}s`}
            onChange={(value) => setPlaybackDuration(value)} />
        </Label>
      </aside>

      <footer style={FooterStyle}>
        <Player 
          duration={audioDuration}
          position={position}
          playing={playing}
          onSeek={onPlayerSeek}
          skipForwardDuration={skipForwardDuration}
          skipBackwardDuration={skipBackwardDuration} 
          playbackDuration={playbackDuration}
          onSkipBackward={onPlayerSkipBackward}
          onSkipForward={onPlayerSkipForward}
          onPlay={onPlayerPlay} 
        />
      </footer>
    </>
  );
}

const ASIDE_WIDTH = '384px';
const HEADER_HEIGHT = '68px';
const FOOTER_HEIGHT = '68px';

const HeaderStyle = {
  position: 'absolute',
  height: HEADER_HEIGHT,
  right: 0,
  top: 0,
  left: 0,
  background: '#f5f8fa',
  borderBottom: '1px solid #e3e6e8'
};

const MainStyle = {
  position: 'absolute',
  top: HEADER_HEIGHT,
  left: 0,
  right: ASIDE_WIDTH,
  bottom: FOOTER_HEIGHT,
  background: '#fff',  
};

const AsideStyle = {
  position: 'absolute',
  bottom: FOOTER_HEIGHT,
  right: 0,
  top: HEADER_HEIGHT,
  width: ASIDE_WIDTH,
  background: '#f5f8fa',
  borderLeft: '1px solid #e3e6e8'
};

const FooterStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: FOOTER_HEIGHT,
  background: '#f5f8fa',
  borderTop: '1px solid #e3e6e8'
};

const AsideLabelStyle = {
  marginLeft: '24px',
  marginRight: '24px',
  marginTop: '16px',
  marginBottom: '32px',
  paddingBottom: '20px',
  borderBottom: '1px solid #e3e6e8',
};

const CloseButtonStyle = {
  float: 'right',
};

export default EditorView;