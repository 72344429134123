import { useEffect } from 'react';

import CONFIG from 'config';

function useTitle(title) {
  useEffect(() => {
    if(title) {
      window.document.title = `${title} – ${CONFIG.APP_NAME}`;
    } else {
      window.document.title = CONFIG.APP_NAME;
    }
  }, [title]);
}

export { useTitle };