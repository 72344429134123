
const countStatistics = (value) => {
    let charactersWithSpaces = 0;
    let charactersWithoutSpaces = 0;
    let words = 0;
  
    for(const element of value) {
      const childrenText = element.children.reduce((acc, child) => child.text ? acc + child.text : acc, '');
      charactersWithSpaces += childrenText.length;
      charactersWithoutSpaces += childrenText.replace(/\s/g, '').length;
      const wordsMatch = childrenText.match(/[\w\d’'-]+/gi);
      if(wordsMatch) {
        words += wordsMatch.length;
      }
    }
  
    return {words, charactersWithSpaces, charactersWithoutSpaces};
  }

  export { countStatistics };