import { useRef, useEffect } from 'react';

// This hook will remember the previous value of the given value
//
// Taken from https://usehooks.com/usePrevious/
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export { usePrevious }