import React from 'react';

const StatisticsPanel = ({height, charactersWithSpaces, charactersWithoutSpaces, words}) => {
  return (
    <div style={{...OuterStyle, height}}>
      <div style={InnerStyle}>
        Liczba znaków: <span style={CounterStyle}>{charactersWithSpaces}</span> ze spacjami, <span style={CounterStyle}>{charactersWithoutSpaces}</span> bez spacji. Liczba słów: <span style={CounterStyle}>{words}</span>.
      </div>
    </div>
  )
}

const OuterStyle = {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  fontSize: '8pt',
  background: '#fff',
  borderTop: '1px solid #e3e6e8'
};

const InnerStyle = {
  position: 'absolute',
  left: '8px',
  right: '8px',
  top: '4px',
};

const CounterStyle = {
  fontFamily: 'Andale Mono, monospace',
};

export default StatisticsPanel;